<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('log_coupon.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ $t('log_coupon.head_list') }} </v-card-title>
        <v-card-actions class="pb-0 d-flex align-start">
          <v-row no-gutters>
            <span>
              <date-picker-input :label="$t('log_coupon.date_from')" :date="dateFrom" @setValue="dateFrom = $event" />
            </span>
            <span>
              <date-picker-input :label="$t('log_coupon.date_to')" :date="dateTo" @setValue="dateTo = $event" />
            </span>
            <v-btn color="primary" class="" @click="searchByDate"> {{ $t('search') }}  </v-btn>
            <v-btn color="warning" v-if="isExportLogCoupon" @click="preloadExportCsv">{{ $t('log_coupon.export_excel') }}</v-btn>
          </v-row>
        </v-card-actions>
        <v-card-actions>
          <div class="d-flex" style="width:100%;justify-content: space-between; align-items: center">
            <v-card-title class="pa-0">
              {{ $t('log_coupon.summary') }} ({{ dateFrom }} {{ $t('to') }} {{ dateTo }}) :
              <span class="ml-1" style="color: #28c76f !important; font-weight: 700">{{ total | currency }}</span>
            </v-card-title>
            <v-card-actions class="pa-0">
              <v-spacer />
              <span class="px-3">{{ $t('search') }}:</span>
              <div style="width: 150px" class="">
                <v-text-field v-debounce:500ms="searchKeyword" outlined dense hide-details />
              </div>
            </v-card-actions>
          </div>
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="logs"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.createdAt`]="{ item }">
              <span>{{ formatDate(item.createdAt) }}</span>
            </template>
            <template v-slot:[`item.status`]>
              <span style="color: #28c76f !important">{{ $t('log_coupon.completed') }}</span>
            </template>
            <!-- <template v-slot:[`item.action`]="{ item }">
              <v-btn  class="white--text text-capitalize" text>
                  <v-icon color="error" small class="px-3">mdi-delete</v-icon>
                <span class="black--text">ลบ</span>
              </v-btn>
            </template> -->
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import * as FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import store from '@/store'
import moment from 'moment'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      dateFrom: moment().subtract(1, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      date: null,
      currentMemberDetail: {},
      headers: [
        {
          text: this.$t('log_coupon.header1'),
          value: 'phone',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_coupon.header2'),
          value: 'credit',
          width: '100px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_coupon.header3'),
          value: 'turnOver',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_coupon.header4'),
          value: 'status',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_coupon.header5'),
          value: 'detail',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('log_coupon.header6'),
          value: 'createdAt',
          width: '95px',
          align: 'center',
          sortable: false,
        },
      ],
      logs: [],
      total: 0,
      search: '',
      loading: false,
      dummy: true,
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
      reportRecordLimit: 2000000,
      isExportLogCoupon: false
    }
  },
  async created() {
    this.addLogPage()
    this.getExportData()
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont && !this.dummy) {
          this.loading = true
          const result = this.searchData
            ? await this.getLogCouponData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`,
            )
            : await this.getLogCouponData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}`,
            )
          this.logs = result.data
          this.total = result.totalCredit
          this.pagination.totalItems = result.count
          this.loading = false
        }
        if (cont && this.dummy) {
          this.loading = true
          const result = this.searchData
            ? await this.getLogCouponData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`,
            )
            : await this.getLogCouponData(
              `page=${newValue.page}&rows=${newValue.itemsPerPage}&time_from=${this.formatDateYYYY(
                this.dateFrom,
              )}&time_to=${this.formatDateYYYY(this.dateTo)}`,
            )
          this.logs = result.data
          this.total = result.totalCredit
          this.pagination.totalItems = result.count
          this.loading = false
        }
      },
      deep: true,
    },
  },
  async mounted() {
    try {
      // const result = await this.$store.dispatch('getCouponDetail', `id=${this.$route.params.value}&page=1$row=10`)
      // this.pagination.totalItems = result.count
      // this.coupons = result.data
    } catch (e) { }
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'Log คูปอง',
            url: window.location.href,
            detail: 'Log รคูปอง',
            admin: userSystem.name ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async searchByDate() {
      this.loading = true
      this.dummy = false
      const params = this.searchData
        ? `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(
          this.dateFrom,
        )}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`
        : `page=1&rows=${this.pagination.itemsPerPage}&time_from=${this.formatDateYYYY(
          this.dateFrom,
        )}&time_to=${this.formatDateYYYY(this.dateTo)}`
      const result = await this.getLogCouponData(params)
      this.pagination.totalItems = result.count
      this.pagination.page = 1
      this.logs = result.data
      this.total = result.totalCredit
      this.loading = false
    },
    async searchKeyword(value) {
      this.loading = true
      let result = {}
      if (value) {
        result = await this.$store.dispatch(
          'getLogCoupon',
          `&page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${value}`,
        )
      } else {
        result = await this.$store.dispatch(
          'getLogCoupon',
          `&page=1&rows=${this.pagination.rowsPerPage}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`,
        )
      }
      this.pagination.totalItems = result.count
      this.logs = result.data
      this.total = result.totalCredit
      this.loading = false
    },
    async getLogCouponData(params) {
      try {
        return await this.$store.dispatch('getLogCoupon', params)
      } catch (e) { }
    },
    async getExportData() {
      await this.$store.dispatch('getSettingFeature').then(res => {
        const chkShow = res.filter((item) => item.column === 'exportLogCoupon')[0]?.value === 1 ? true : false;
        this.isExportLogCoupon = chkShow;
      })
    },
    formatDate(date) {
      return moment(date).format('YY-MM-DD HH:mm')
    },
    formatDateSS(date) {
      return moment(date).format('YY-MM-DD HH:mm:ss')
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },

    async preloadExportCsv() {
      //////// get logExportData ////////
      let logExportData;
      const userSystem = store.getters.getuserInfo
      await axios.get('https://api.ipify.org?format=json').then(res =>
        logExportData = {
          ip: res.data.ip,
          exportPage: 'Log คูปอง',
          url: window.location.href,
          detail: 'Log คูปอง',
          admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
        }
      )

      this.$swal({
        title: `Secure code | รายงาน${logExportData.exportPage}`,
        input: 'password',
        // inputAttributes: {
        //   inputmode: 'numeric',
        //   pattern: '[0-9]*',
        //   maxlength: 6,
        // },
        inputValidator: value => {
          if (!value) {
            return 'โปรดกรอก Secure code'
          }
          // if (value && value.length !== 6) {
          //   return 'โปรดกรอกให้ครบ 6 หลัก'
          // }
        },
        inputPlaceholder: 'ใส่รหัสที่นี่...',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ออก',
      }).then(async result => {
        if (result.isConfirmed) {
          const pin = result.value
          let enterSecureData = {
            ...logExportData,
            keyCheck: pin
          }
          this.loading = true
          let resEnterSecureData = await this.$store.dispatch('checkLogEnterSecure', enterSecureData)
          if(resEnterSecureData && resEnterSecureData.secure){
            this.exportCsv()
          } else {
            this.$swal.fire('ไม่สามารถ download ได้ เนื่องจากรหัส PIN ไม่ถูกต้อง', '', 'error')
          }
          this.loading = false
        }
      })
    },
    async exportCsv() {
      this.$swal.fire({
        html: this.$t('processing'),
        timerProgressBar: true,
        didOpen: () => {
          this.$swal.showLoading()
        },
      })

      let fileName = `รายงาน Log คูปอง_${moment(new Date()).format('YYYY-MM-DD_HH-mm')}`
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const wscols = [
        { wch: 15 },
        { wch: 10 },
        { wch: 10 },
        { wch: 20 },
        { wch: 80 },
        { wch: 15 },
      ]
      let statementlist = []
      let params = `page=1&rows=${this.reportRecordLimit}`
      this.searchData
        ? (params = `${params}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}&search=${this.searchData}`)
        : params = `${params}&time_from=${this.formatDateYYYY(this.dateFrom)}&time_to=${this.formatDateYYYY(this.dateTo)}`

      let rs = await this.$store.dispatch('getLogCoupon', params)
      rs.data.forEach(element => {
        statementlist.push({
          'สมาชิก': element.phone,
          'คูปองมูลค่า': element.credit,
          'เทิร์นโอเวอร์': element.turnOver,
          'สถานะ': 'ดำเนินการเรียบร้อย',
          'รายละเอียด': element.detail,
          'วันที่ใช้': this.formatDate(element.createdAt)
        })
      })
      let ws = XLSX.utils.json_to_sheet(statementlist)
      ws['!cols'] = wscols
      const wb = { Sheets: { LogCouponReport: ws }, SheetNames: ['LogCouponReport'] }
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
      const data = new Blob([excelBuffer], { type: fileType })
      FileSaver.saveAs(data, fileName + fileExtension)

      // add log admin export
      await this.addLogExportCSV()
      this.$swal.close()
    },
    async addLogExportCSV() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          data = {
            ip: res.data.ip,
            exportPage: 'Log คูปอง',
            url: window.location.href,
            detail: 'Log คูปอง',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }
        )
        await this.$store.dispatch('addLogExportCSV', data)
      } catch (e) {
        if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
  },
}
</script>
